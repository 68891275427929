import React, { useState } from 'react';

function FAQSection() {
  const [faq, setFaq] = useState({
    question1: false,
    question2: false,
    question3: false,
    question4: false,
    question5: false,
    question6: false,
    question7: false,
    question8: false,
    question9: false,
    question10: false,
    question11: false,
  });

  const toggleFAQ = (question) => {
    setFaq({ ...faq, [question]: !faq[question] });
  };

  return (
    <div className="container mx-auto px-6 py-8">
      <h2 className="text-3xl font-bold text-center mb-8">Häufig gestellte Fragen (FAQ)</h2>
      <div className="space-y-4">

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question1')}
          >
            Wie funktioniert HUGO?
          </h3>
          {faq.question1 && (
            <p className="text-gray-700">
              HUGO arbeitet mit hochpräzisen Pumpen, die eine gleichbleibend hohe Cocktailqualität sicherstellen. Jede Zutat wird exakt dosiert, um den perfekten Cocktail zu kreieren.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question2')}
          >
            Sind auch weniger als 100 Cocktails möglich?
          </h3>
          {faq.question2 && (
            <p className="text-gray-700">
              Die Mindestabnahme von 100 Cocktails ist im Basispaket enthalten. Da die Vorbereitung und Reinigung aufwendig ist, können wir bei kleineren Mengen keine wirtschaftliche Umsetzung gewährleisten.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question3')}
          >
            Wie lange dauert der Aufbau der Maschine?
          </h3>
          {faq.question3 && (
            <p className="text-gray-700">
              Der Aufbau von HUGO dauert etwa 30 Minuten. Die Einweisung in die Bedienung nimmt maximal 10 Minuten in Anspruch.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question4')}
          >
            Was passiert, wenn Zutaten ausgehen?
          </h3>
          {faq.question4 && (
            <p className="text-gray-700">
              Wenn eine Zutat leer ist, erhalten die eingewiesenen Personen automatisch eine SMS-Benachrichtigung. Bis zum Nachfüllen werden Cocktails mit fehlenden Zutaten nicht angezeigt, um die Qualität sicherzustellen.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question5')}
          >
            Wie viele Cocktails sollte ich pro Person einplanen?
          </h3>
          {faq.question5 && (
            <p className="text-gray-700">
              Für Abendveranstaltungen empfehlen wir, etwa 4 bis 5 Cocktails pro Person einzuplanen. Cocktails haben einen besonderen Reiz und sind oft das Highlight der Feier.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question6')}
          >
            Stoppt HUGO automatisch, wenn die bestellte Menge erreicht ist?
          </h3>
          {faq.question6 && (
            <p className="text-gray-700">
              HUGO arbeitet weiter, solange noch Zutaten vorhanden sind. Ihr könnt über das Webinterface jederzeit die Anzahl der servierten Cocktails überwachen. Bei Erreichen des Limits kann HUGO wie in der Einweisung erklärt abgeschaltet werden.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question7')}
          >
            Müssen die Gläser nach der Veranstaltung gereinigt werden?
          </h3>
          {faq.question7 && (
            <p className="text-gray-700">
              Ja, die Gläser sollten gespült zurückgegeben werden. Sie sind spülmaschinengeeignet. Sollte die Reinigung nicht erfolgen, wird eine Gebühr berechnet.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question8')}
          >
            Wie erfolgt die Bezahlung?
          </h3>
          {faq.question8 && (
            <p className="text-gray-700">
              50% des Basispakets sind bei Auftragsbestätigung zu zahlen. Der Restbetrag wird eine Woche vor der Veranstaltung fällig. Der Mehrverbrauch kann entweder bar vor Ort oder im Nachgang per Überweisung beglichen werden. Selbstverständlich erhaltet ihr eine ordentliche Rechnung.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question9')}
          >
            Ist HUGO laut?
          </h3>
          {faq.question9 && (
            <p className="text-gray-700">
              HUGO erzeugt Geräusche während der Zubereitung, diese sind jedoch dezent und bei laufender Musik kaum wahrnehmbar.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question10')}
          >
            Wie lange dauert die Zubereitung eines Cocktails?
          </h3>
          {faq.question10 && (
            <p className="text-gray-700">
              Ein Cocktail wird in der Regel in unter 10 Sekunden zubereitet – abhängig von den verwendeten Zutaten.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3
            className="text-xl font-bold mb-2 cursor-pointer"
            onClick={() => toggleFAQ('question11')}
          >
            Kann HUGO auch auf Kindergeburtstagen eingesetzt werden?
          </h3>
          {faq.question11 && (
            <p className="text-gray-700">
              Ja, HUGO eignet sich auch für Kinderveranstaltungen. In diesem Fall werden nur alkoholfreie Cocktails serviert.
            </p>
          )}
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
  <h3
    className="text-xl font-bold mb-2 cursor-pointer"
    onClick={() => toggleFAQ('question12')}
  >
    In welchem Gebiet liefert ihr HUGO?
  </h3>
  {faq.question12 && (
    <p className="text-gray-700">
      HUGO liefern wir im gesamten Umkreis von 50 Kilometern um Herford. Dazu gehören Städte wie Bielefeld, Bad Salzuflen, Detmold, Minden, Löhne, Gütersloh, Enger, Spenge, und Vlotho. Wir bedienen auch Orte wie Lemgo, Bad Oeynhausen, Hiddenhausen, Porta Westfalica, und Rinteln. Unser Einzugsgebiet umfasst sowohl NRW als auch Niedersachsen und erstreckt sich über Ostwestfalen-Lippe (OWL) und Teile Niedersachsens, mit Städten wie Paderborn, Höxter, Osnabrück, Lübbecke, Espelkamp, Rahden, und Petershagen. Für besondere Anfragen in angrenzenden Regionen stehen wir gerne zur Verfügung.
    </p>
  )}
</div>



      </div>
    </div>
  );
}

export default FAQSection;
