import React from 'react';

function Preisliste() {
  return (
    <div className="container mx-auto px-6 py-16">
      <h2 className="text-3xl font-bold text-center mb-8">Preisliste</h2>

      {/* 2 Karten pro Reihe */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Basispaket</h2>
          <ul className="list-disc list-inside text-gray-700 mb-4">
            <li>Lieferung, Aufbau, Einweisung, Abbau, Abholung, Reinigung</li>
            <li>Cocktailmaschine HUGO</li>
            <li><strong>100 Cocktails inklusive!</strong></li>
            <li>Gläser in passender Anzahl</li>
            <li>Edelstahl-Strohhalme in passender Anzahl</li>
            <li>Eiswürfel in ausreichender Menge</li>
            <li>Cocktailservietten</li>
            <li>Digitale Cocktailkarte mit Such- und Filterfunktion</li>
            <li>Markensäfte und Markenspirituosen für alle Cocktails</li>
          </ul>
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Kosten für das Basispaket</h2>
          <ul className="list-disc list-inside text-gray-700 mb-4">
            <li>bei Anfahrt bis 10 km: 475 €</li>
            <li>bei Anfahrt von 11 bis 20 km: 485 €</li>
            <li>bei Anfahrt von 21 bis 30 km: 495 €</li>
            <li>bei Anfahrt von 31 bis 40 km: 505 €</li>
            <li>bei Anfahrt über 40 km: Auf Anfrage</li>
          </ul>
          <p className="text-gray-600 italic">
            Wichtig: Die Anlieferung erfolgt grundsätzlich ebenerdig oder ins Erdgeschoss. Falls die Lieferung in andere Stockwerke ohne Aufzug gewünscht ist, muss eventuell eine Tragehilfe organisiert werden.
          </p>
        </div>
      </div>

      {/* Weitere 2 Karten pro Reihe */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Cocktailpreise</h2>
          <p className="text-gray-700 mb-4">
            Im Basispaket sind <strong>100 Cocktails</strong> enthalten. </p>
            
            <p>Ab dem 101ten Cocktail nur 3,00 Euro pro Cocktail.
          </p>
          
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Optionales Personal</h2>
          <p className="text-gray-700">
  Ihr habt keinen, der HUGO nachfüllt oder die Gläser einsammelt? Dann bucht Personal zum Nachfüllen von HUGO und zum Einsammeln/Reinigen der Gläser dazu.
</p>
<ul className="list-disc list-inside text-gray-700 mb-2 mt-4">
  <li>Preis: ab 20 Euro pro Stunde</li>
</ul>
        </div>
      </div>

      {/* Weitere 2 Karten pro Reihe */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Kaution</h2>
          <p className="text-gray-700">
            Die Kaution ist abhängig von der Anzahl der Gäste und den gelieferten Getränken. Sie beträgt zwischen <strong>200 € und 400 €</strong> (in bar).
          </p>
        </div>

        
      </div>
    </div>
  );
}

export default Preisliste;
