import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';

// Bilder für das Karussell importieren, die vom responsive-loader generiert werden
import cocktail1 from '../images/cocktail_1.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import cocktail2 from '../images/cocktail_2.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import cocktail3 from '../images/cocktail_3.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import cocktail4 from '../images/cocktail_4.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import cocktail5 from '../images/cocktail_5.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import cocktail6 from '../images/cocktail_6.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import cocktail7 from '../images/cocktail_7.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import cocktail8 from '../images/cocktail_8.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import partyzelt1 from '../images/partyzelt_5x8_1.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import partyzelt2 from '../images/partyzelt_5x8_2.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import partyzelt3 from '../images/partyzelt_5x8_3.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import partyzelt4 from '../images/partyzelt_5x8_4.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import partyzelt5 from '../images/partyzelt_5x8_5.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import partyzelt6 from '../images/partyzelt_5x8_6.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import partyzelt7 from '../images/partyzelt_5x8_7.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';
import partyzelt8 from '../images/partyzelt_5x8_8.JPG?sizes[]=200,sizes[]=300,sizes[]=640,sizes[]=960,sizes[]=1200';

const images = [
  cocktail1,
  cocktail2,
  cocktail3,
  cocktail4,
  cocktail5,
  cocktail6,
  cocktail7,
  cocktail8,
  partyzelt1,
  partyzelt2,
  partyzelt3,
  partyzelt4,
  partyzelt5,
  partyzelt6,
  partyzelt7,
  partyzelt8,
];

const services = [
  {
    title: 'Partyzelte',
    description: 'Hochwertige Zelte für jede Veranstaltung, individuell anpassbar.',
    image: partyzelt1,
    link: '/partyzelte',
  },
  {
    title: 'Cocktailmaschine HUGO',
    description: 'Mieten Sie unsere moderne Cocktailmaschine für köstliche Drinks.',
    image: cocktail1,
    link: '/cocktailmaschine',
  },
];

function Start() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // Automatischer Bildwechsel alle 5 Sekunden
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        handleNext();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [activeIndex, isPaused]);

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    setIsPaused(true); // Manuelle Aktion pausiert den Timer
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    setIsPaused(true); // Manuelle Aktion pausiert den Timer
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    setIsPaused(true); // Manuelle Aktion pausiert den Timer
  };

  return (
    <div>
      {/* React Helmet für SEO und Preloading */}
      <Helmet>
        <title>Cocktailmaschine & Partyzelte - mieten, leihen, feiern - Eventservice Taeger</title>
        <meta
          name="description"
          content="Mieten Sie die innovative Cocktailmaschine HUGO und hochwertige Partyzelte für Ihre Feier. Eventservice Taeger bietet alles, um Ihre Veranstaltung unvergesslich zu machen."
        />
        <meta
          name="keywords"
          content="Cocktailmaschine mieten, Partyzelt mieten, Eventservice, Zeltverleih Herford, HUGO Cocktailmaschine, Eventzubehör, Hochzeitszelt, Firmenfeier Cocktailmaschine"
        />
        <link rel="canonical" href="https://eventservice-taeger.de/" />
        <link rel="icon" href="/favicon.ico"></link>
        {/* Preload für LCP-Bilder */}
        {images.map((image, index) => (
          <link key={index} rel="preload" href={image.src} as="image" />
        ))}
      </Helmet>

      {/* Überschrift und Untertitel */}
      <div className="container mx-auto px-6 py-6 text-center">
        <h1 className="text-black text-4xl font-bold mb-4">
          Willkommen bei Eventservice Taeger
        </h1>
        <p className="text-black text-xl">
          Cocktailmaschinen, Partyzelte und mehr in der Region Herford mieten.
        </p>
      </div>

      {/* Karussell */}
      <div className="container mx-auto px-6 py-6 grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Große Bildanzeige */}
        <div className="relative bg-white shadow-lg rounded-lg p-4 flex justify-center items-center">
          <img
            src={images[activeIndex].src}
            srcSet={images[activeIndex].srcSet}
            sizes="(max-width: 640px) 640px, (max-width: 960px) 960px, (max-width: 1200px) 1200px, 1800px"
            alt={`Bild ${activeIndex + 1}`}
            className="image-4by3"
            width="960"
            height="720"
          />
          <button
            onClick={handlePrev}
            aria-label="Vorheriges Bild" // Zugänglicher Name
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-black p-3 rounded-full shadow-md"
          >
            <FaArrowLeft size={20} />
          </button>
          <button
            onClick={handleNext}
            aria-label="Nächstes Bild" // Zugänglicher Name
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-black p-3 rounded-full shadow-md"
          >
            <FaArrowRight size={20} />
          </button>
        </div>

        {/* Miniaturbild-Auswahl */}
        <div className="bg-white shadow-lg rounded-lg p-4">
          <div className="grid grid-cols-4 gap-4">
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image.src}
                  srcSet={image.srcSet}
                  sizes="(max-width: 768px) 100px, 150px" // kleinere Bildgrößen für Thumbnails
                  alt={`Thumbnail ${index + 1}`}
                  width="100"
                  height="75"
                  className={`image-4by3 cursor-pointer rounded-lg ${
                    activeIndex === index
                      ? 'border-4 border-blue-500'
                      : 'border border-gray-300'
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Dienstleistungen */}
      <div className="bg-white py-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-8">Unsere Dienstleistungen</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105"
              >
                {service.image && (
                  <img
                    src={service.image.src}
                    srcSet={service.image.srcSet}
                    sizes="(max-width: 640px) 640px, (max-width: 960px) 960px, 1200px"
                    width="960"
                    height="720"
                    alt={service.title}
                    className="image-4by3 w-full h-auto object-cover rounded-md mb-4"
                  />
                )}
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-700 mb-4">{service.description}</p>
                <Link
                  to={service.link}
                  className="text-blue-600 font-semibold hover:underline"
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                  Mehr erfahren &rarr;
                </Link>
              </div>
            ))}

            {/* Statische Karte */}
            <div className="bg-white p-6 rounded-lg shadow-lg h-auto transform transition-transform hover:scale-105 flex flex-col justify-center items-center">
              <h3 className="text-xl font-semibold mb-2">Weitere spannende Dienstleistungen</h3>
              <p className="text-gray-700 mb-4 text-center">
                Seien Sie gespannt – in Kürze werden wir unser Angebot noch erweitern!
              </p>
              <span className="text-gray-500 italic">Bald verfügbar</span>
            </div>
          </div>
        </div>
      </div>

      {/* Call-to-Action */}
      <div className="bg-blue-600 py-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">
            Bereit für Ihr nächstes Event?
          </h2>
          <p className="text-white mb-8">
            Kontaktieren Sie uns noch heute und lassen Sie uns gemeinsam Ihre
            Veranstaltung planen.
          </p>
          <Link
            to="/kontakt"
            className="bg-white text-blue-600 font-semibold px-8 py-3 rounded-full"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            Kontakt aufnehmen
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Start;
